import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState, useLayoutEffect, useRef, Suspense, useContext, createContext } from 'react';
import { LGraphCanvas, LGraph, LiteGraph } from 'litegraph.js';
import 'litegraph.js/css/litegraph.css';

import Label from '@playcanvas/pcui/Label/component';
import Button from '@playcanvas/pcui/Button/component';
import Container from '@playcanvas/pcui/Container/component';

import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber'
import * as THREE from "three"
import { useCamera, useGLTF } from "@react-three/drei"
import { TransformControls, PerspectiveCamera, OrbitControls, RoundedBox } from '@react-three/drei'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader';

import { drawConnectors, drawRectangle } from '@mediapipe/drawing_utils'
import MediaPipeFaceMesh, { FaceMesh } from '@mediapipe/face_mesh'
import { Camera } from '@mediapipe/camera_utils'

import System, { SpriteRenderer } from 'three-nebula';

import TestLaserEffect from './TestLaserEffect';
import { log } from 'three-nebula/build/cjs/debug';


function App() {

  // const [nodes, setNodes] = useState(null);
  // const [texture, setTexture] = useState(null);

  // useEffect(() => {
  //   var loader = new GLTFLoader();

  //   const dracoLoader = new DRACOLoader();
  //   dracoLoader.setDecoderPath('../node_modules/three/examples/js/libs/draco/gltf/');
  //   dracoLoader.setDecoderConfig({ type: 'js' });
  //   loader.setDRACOLoader(dracoLoader);

  //   loader.load(process.env.PUBLIC_URL + "/baseAva1.gltf", (gltf) => {
  //     setNodes(gltf.scene);
  //     console.log(gltf.scene);
  //   });

  //   var loader2 = new BasisTextureLoader();
  //   loader2.load(process.env.PUBLIC_URL + "/newape.png", (tmexture) => {
  //     setTexture(tmexture);
  //   })
  // }, []);

  // const [context, setContext] = useState({
  //   graph: new LGraph(),
  //   boneNodes: [],
  //   setBoneNodes: (nodes) => {
  //     // console.log(nodes);
  //     setContext({ ...context, boneNodes: nodes });
  //   },
  // });

  const [boneNodes, setBoneNodes] = useState([]);
  const [graph, setGraph] = useState(new LGraph());
  graph.start();

  const value = {
    graph,
    boneNodes,
    setBoneNodes,
  }

  // useEffect(() => {
  //   context.graph.start()
  // }, []);

  return (
    <div className="App" style={{
      backgroundColor: '#2f2f2f',
    }}>
      <div className="text-left  px-4 py-2 font-bold text-white" style={{
        backgroundColor: '#3C3C3C',
      }}>Avatech Studio <span className="border px-1 text-xs rounded-sm">ALPHA</span></div>
      <Suspense fallback={
        <div>Loading</div>
      }>
        <AvatechContext.Provider
          value={value}
        >
          <CoreView />
        </AvatechContext.Provider>
      </Suspense>
    </div >
  );
}

const AvatechContext = createContext({});

function CoreView() {
  const { nodes } = useGLTF(process.env.PUBLIC_URL + "/baseAva1.gltf")
  const texture = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/newape.png")

  const avatechContext = useContext(AvatechContext)

  // const [customObjects, setCustomObjects] = useState([]);
  const [mode, setMode] = useState(0)

  useEffect(() => {
    const videoElement = document.getElementsByClassName('input_video')[0];
    const canvasElement = document.getElementsByClassName('output_canvas')[0];
    const canvasCtx = canvasElement.getContext('2d');

    var lastClosed = false;
    function onResults(results) {
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
      canvasCtx.drawImage(
        results.image, 0, 0, canvasElement.width, canvasElement.height);
      if (results.multiFaceLandmarks) {
        for (const landmarks of results.multiFaceLandmarks) {
          // drawConnectors(canvasCtx, landmarks, MediaPipeFaceMesh.FACEMESH_TESSELATION,
          //   { color: '#C0C0C070', lineWidth: 1 });
          drawConnectors(canvasCtx, landmarks, MediaPipeFaceMesh.FACEMESH_RIGHT_EYE, { color: '#FF3030', lineWidth: 2 });
          // drawConnectors(canvasCtx, landmarks, MediaPipeFaceMesh.FACEMESH_RIGHT_EYEBROW, { color: '#FF3030' });
          // drawConnectors(canvasCtx, landmarks, MediaPipeFaceMesh.FACEMESH_RIGHT_IRIS, { color: '#FF3030' });
          drawConnectors(canvasCtx, landmarks, MediaPipeFaceMesh.FACEMESH_LEFT_EYE, { color: '#30FF30', lineWidth: 2 });
          // var o = []
          var leftP1;
          var leftP2;

          var rightP1;
          var rightP2;

          MediaPipeFaceMesh.FACEMESH_LEFT_EYE.forEach((x, i) => {
            // o.push([landmarks[x[0]], landmarks[x[1]]])
            var point = landmarks[x[0]];
            if (i == 4) {
              drawRectangle(canvasCtx, {
                xCenter: point.x,
                yCenter: point.y,
                height: 0.005,
                width: 0.005,
                rectId: i,
              });
              leftP1 = point;
            }

            if (i == 12) {
              var point = landmarks[x[1]];

              drawRectangle(canvasCtx, {
                xCenter: point.x,
                yCenter: point.y,
                height: 0.005,
                width: 0.005,
                rectId: i,
              });
              leftP2 = point;
            }
          });

          MediaPipeFaceMesh.FACEMESH_RIGHT_EYE.forEach((x, i) => {
            // o.push([landmarks[x[0]], landmarks[x[1]]])
            var point = landmarks[x[0]];
            if (i == 4) {
              drawRectangle(canvasCtx, {
                xCenter: point.x,
                yCenter: point.y,
                height: 0.005,
                width: 0.005,
                rectId: i + 32,
              });
              rightP1 = point;
            }

            if (i == 12) {
              var point = landmarks[x[1]];

              drawRectangle(canvasCtx, {
                xCenter: point.x,
                yCenter: point.y,
                height: 0.005,
                width: 0.005,
                rectId: i + 32,
              });
              rightP2 = point;
            }
          });

          var approxLeft = Math.abs(leftP1.y * 500 - leftP2.y * 500);
          var approxRight = Math.abs(rightP1.y * 500 - rightP2.y * 500);

          // console.log(approxLeft);
          // console.log(  );

          const k = 6;
          if (approxRight <= k && approxLeft <= k) {
            if (lastClosed) {
              lastClosed = false;
              console.log("both blink!");
              // console.log(avatechContext.graph.findNodeByTitle("BlinkTrigger"));
              avatechContext.graph.findNodeByTitle("BlinkTrigger").triggerSlot(0, "");
            }
          } else {
            lastClosed = true;
          }

          // console.log(o);

          // drawConnectors(canvasCtx, landmarks, MediaPipeFaceMesh.FACEMESH_LEFT_EYEBROW, { color: '#30FF30' });
          // drawConnectors(canvasCtx, landmarks, MediaPipeFaceMesh.FACEMESH_LEFT_IRIS, { color: '#30FF30' });
          drawConnectors(canvasCtx, landmarks, MediaPipeFaceMesh.FACEMESH_FACE_OVAL, { color: '#E0E0E0' });
          drawConnectors(canvasCtx, landmarks, MediaPipeFaceMesh.FACEMESH_LIPS, { color: '#E0E0E0' });

          // console.log(landmarks);
        }
      }
      canvasCtx.restore();
    }

    const faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      }
    });
    faceMesh.setOptions({
      maxNumFaces: 1,
      refineLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5
    });
    faceMesh.onResults(onResults);

    const camera = new Camera(videoElement, {
      onFrame: async () => {
        await faceMesh.send({ image: videoElement });
      },
      width: 500,
      height: 500
    });
    camera.start();
  }, [])

  return (
    <div className="flex-row flex w-full h-full">
      <Container
        enabled
        height={'100vh'}
        resizable=""
        resizeMax={0}
        resizeMin={0}
        tabIndex={0}
        width={'50%'}
        className="flex flex-column"
      >
        <Label text="Graph" className="text-left" />

        {/* <AvatechContext.Consumer> */}
        <GraphView nodes={nodes} style={{
          backgroundColor: '#222222',
        }} />
        {/* </AvatechContext.Consumer> */}

      </Container>

      <Container
        enabled
        height={'100vh'}
        resizable=""
        resizeMax={0}
        resizeMin={0}
        tabIndex={0}
        width={'50%'}
        className="flex flex-column"
      >
        <div>
          <Label text="Preview" className="text-left" />
          {/* <Button
            enabled
            height={null}
            // icon="E401"
            onClick={() => {
              setMode(0)
            }}
            size=""
            tabIndex={0}
            text="Scene"
            width={null}
          />
          <Button
            enabled
            height={null}
            // icon="E401"
            onClick={() => {
              setMode(1)
            }}
            size=""
            tabIndex={0}
            text="Move"
            width={null}
          /> */}
        </div>

        {/* <AvatechContext.Consumer> */}
        {/* customObjects={customObjects} */}
        <AvatarView nodes={nodes} texture={texture} mode={mode} />
        {/* </AvatechContext.Consumer> */}

        <div>
          <video className="input_video hidden"></video>
          <canvas className="output_canvas absolute right-0 bottom-0" width="250px" height="250px" ></canvas>
        </div>

      </Container>

    </div>
  )
}

var nodeCanvas;

export default App;


function AvatarView(props) {
  const myCamera = useRef()
  const threeScene = useRef()
  const orbitControl = useRef()
  const transformControl = useRef()

  const avatechContext = useContext(AvatechContext)

  // useEffect(() => {
  //   function test(params) {

  //   }

  //   const renderer = new SpriteRenderer(threeScene, THREE);
  //   System.fromJSONAsync(TestLaserEffect, THREE).then(system => {
  //     console.log(system);
  //     system.addRenderer(renderer)
  //     system.emit(test, test, test)
  //   });

  //   console.log(threeScene.current);
  // }, [])

  useEffect(() => {
    console.log(orbitControl.current);
    // if (props.mode == 0)
    // orbitControl.current.focus();
  }, [props.mode]);

  return (
    <Canvas {...props} >
      <scene ref={threeScene}>
        <PerspectiveCamera ref={myCamera} position={[0, 5, 5]} />
        <OrbitControls camera={myCamera.current} ref={orbitControl} enabled={props.mode == 0} />
        <ambientLight />
        {/* <pointLight position={[10, 10, 10]} /> */}
        {/* <Box position={[-1.2, 0, 0]} /> */}

        <Suspense fallback={null}>

          {/* onPointerDown={() => {
              console.log("Down");
              orbitControl.current.enabled = false;
            }}

            onPointerUp={() => {
              console.log("Up");
              orbitControl.current.enabled = true;
            }} */}


          {
            props.nodes != null && props.texture != null ?
              <SkinnedAvatar position={[0, 0, 0]} {...props} avatechContext={avatechContext} orbitControl={orbitControl} mode={props.mode} showLaser={true} />
              : null
          }

        </Suspense>
      </scene>
    </Canvas>
  )
}

function AvatarBoneOutput() {
  this.addInput("rotation", "vec3");
  this.addInput("scale", "vec3", [1, 1, 1]);
}

//name to show
AvatarBoneOutput.title = "AvatarBoneOutput";
AvatarBoneOutput.position = [10, 50];
AvatarBoneOutput.size = [120, 50];

//function to call when the node is executed
AvatarBoneOutput.prototype.onExecute = function () {
  // var A = this.getInputData(0);
  // if (A === undefined)
  //   A = 0;
  // var B = this.getInputData(1);
  // if (B === undefined)
  //   B = 0;
  // this.setOutputData(0, A + B);
}

//register in the system
LiteGraph.registerNodeType("avatech/AvatarBoneOutput", AvatarBoneOutput);


function OnTrigger() {
  this.size = [60, 30];
  this.addOutput("event", LiteGraph.EVENT);

  var that = this;
  this.addWidget("button", "Simulate Trigger", null, function () {
    that.triggerSlot(0, "");
  });

}
OnTrigger.title = "OnTrigger";
OnTrigger.prototype.onAction = function (action, param) {
  console.log(action, param);
};
LiteGraph.registerNodeType("avatech/Trigger", OnTrigger);



function OnTrigger2() {
  this.size = [60, 30];
  this.addInput("event", LiteGraph.ACTION);
}
OnTrigger2.title = "ActivateLaser";
OnTrigger2.prototype.onAction = function (action, param) {
  // return if lastTriggeredTime and now is less than 1 sec
  // if (this.lastTriggeredTime && (Date.now() - this.lastTriggeredTime) < 1000)
  //   return;
  lastTriggeredTime = Date.now();
  showLaser = !showLaser;
  console.log(action, param);
};
LiteGraph.registerNodeType("avatech/ActivateLaser", OnTrigger2);

var lastTriggeredTime = Date.now();
var showLaser = false;


function GraphView(props) {

  const avatechContext = useContext(AvatechContext)
  console.log(avatechContext);

  // const [nodeCanvas, setCanvas] = useState({});
  useEffect(() => {
    nodeCanvas = new LGraphCanvas("#mycanvas", avatechContext.graph, {
      autoresize: true
    })

    nodeCanvas.allow_searchbox = false;

    // node_const.connect(0, node_watch, 0);

    var nodes = [];
    props.nodes["Plane"].skeleton.bones.forEach((bone, i) => {
      var node = LiteGraph.createNode("avatech/AvatarBoneOutput");
      node.pos = [600, 100 * (i + 1)];
      node.title = bone.name;
      avatechContext.graph.add(node);

      nodes.push(node)
    });

    avatechContext.setBoneNodes(nodes)

    var node2 = LiteGraph.createNode("avatech/Trigger");
    node2.pos = [100, 600];
    node2.title = "BlinkTrigger";
    avatechContext.graph.add(node2);

    console.log(nodes);

    updateSize();
  }, []);

  function updateSize() {
    const canvas = document.getElementById('mycanvas');
    canvas.width = window.innerWidth / 2;
    canvas.height = window.innerHeight;

    if (nodeCanvas != null)
      nodeCanvas.setDirty(true, true);
    // console.log(nodeCanvas);
  }

  useLayoutEffect(() => {

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <canvas id='mycanvas' width="1024" height="720" {...props}></canvas>
  );
}

function SkinnedAvatar(props) {
  // This reference will give us direct access to the THREE.Mesh object
  // const avatechContext = useContext(AvatechContext)

  // console.log(avatechContext);

  const ref = useRef()

  const [showLaserL, setShowLaser] = useState(false);
  // Set up state for the hovered and active state
  // const [hovered, setHover] = useState(false)
  // const [active, setActive] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  // useFrame(
  //   (state, delta) => {
  //     ref.current.rotation.x += 0.01;
  //     console.log(ref.current.rotation.x);
  //   }
  // )
  // Return the view, these are regular Threejs elements expressed in JSX

  // const [left, setLeft] = useState(true);

  useFrame((state, delta) => {

    // var v = 0.01;

    // if (left) {
    //   v = -0.01;
    // }

    if (showLaser != showLaserL) {
      setShowLaser(showLaser);
    }

    // if (props.nodes["Plane"].skeleton.bones[1].rotation.z > 0.5) {
    //   setLeft(true);
    // } else if (props.nodes["Plane"].skeleton.bones[1].rotation.z <= -0.5) {
    //   setLeft(false);
    // }

    // props.nodes["Plane"].skeleton.bones[1].rotation.z += v;

    // console.log(props.avatechContext);

    if (props.avatechContext.boneNodes != null) {
      props.nodes["Plane"].skeleton.bones.forEach((bone, i) => {
        var rot = props.avatechContext.boneNodes[i].getInputDataByName("rotation")
        if (rot) {
          //   console.log(rot);
          props.nodes["Plane"].skeleton.bones[i].rotation.x = rot[0];
          props.nodes["Plane"].skeleton.bones[i].rotation.y = rot[1];
          props.nodes["Plane"].skeleton.bones[i].rotation.z = rot[2];
        }
      });
    }
  })

  const group = useRef()

  const focusedObjectRef = useRef();
  // skeleton.bones[0].rotation.x = -0.1;

  return (
    // <skinnedMesh
    //   {...props}
    //   ref={ref}
    //   scale={active ? 1.5 : 1}
    //   onClick={(event) => setActive(!active)}
    //   onPointerOver={(event) => setHover(true)}
    //   onPointerOut={(event) => setHover(false)}
    // >
    //   {/* <boxGeometry args={[1, 1, 1]} /> */}

    //   {/* skeleton={nodes["stacy"].skeleton} */}
    //   {/* <skinnedMesh receiveShadow castShadow geometry={myMesh} >
    //     <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} skinning />
    //   </skinnedMesh> */}

    //   {/* <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} /> */}
    // </skinnedMesh>

    // <group ref={group} {...props} dispose={null}>
    /* <primitive object={nodes["mixamorigHips"]} /> */

    /* rotation={[1, 2, 5]} */
    < group ref={ref} >
      <primitive object={props.nodes["Bone"]} />

      {/* <TransformControls mode="translate" object={focusedObjectRef.current} enabled={props.mode == 1} /> */}
      {/* <TransformControls mode="translate" enabled={props.mode == 1} /> */}


      <skinnedMesh
        receiveShadow castShadow geometry={props.nodes["Plane"].geometry} skeleton={props.nodes["Plane"].skeleton}
      // onClick={(event) => focusedObjectRef.current = event.target}
      >
        <meshBasicMaterial map={props.texture} map-flipY={false} />
      </skinnedMesh>

      {/* <TransformControls mode="translate"  > */}
      <RoundedBox args={[1, 0.1, 0.1]} radius={0.05} smoothness={4} position={[0.4, 1.4, 0]} rotation={[0, 0, 0.5]} visible={showLaserL}>
        <meshBasicMaterial attach="material" color="#FF0000" />
      </RoundedBox>

      <RoundedBox args={[1, 0.1, 0.1]} radius={0.05} smoothness={4} position={[0.7, 1.4, 0]} rotation={[0, 0, 0.5]} visible={showLaserL}>
        <meshBasicMaterial attach="material" color="#FF0000" />
      </RoundedBox>
      {/* </TransformControls> */}

      {/* <TransformControls mode="translate"  >
        <RoundedBox args={[1, 0.1, 0.1]} radius={0.05} smoothness={4} rotateZ={1}>
          <meshBasicMaterial attach="material" color="#FF0000" />
        </RoundedBox>
      </TransformControls> */}

    </group >


    // </group>
  )
}